// import logo from "../../assets/images/White-Logo.svg";
import logo from "../../assets/images/White-Logo.png";
import Blacklogo from "../../assets/images/Black-Logo.png";
import oglogo from "../../assets/images/logo.svg";
import leaf1 from "../../assets/images/home/leaf1.png";
import leaf2 from "../../assets/images/home/leaf2.png";
import contact_banner_burger from "../../assets/images/home/contact_banner_burger.png";
import contact_banner_burger_webp from "../../assets/images/home/contact_banner_burger.webp";
import shape_leaf from "../../assets/images/home/who-we-are/video-clipath.png";
import shape_leaf_svg from "../../assets/images/home/who-we-are/video-clipath.svg";
import logo_greeno from "../../assets/images/home/who-we-are/logo_greenovative.svg";
import knowmore_video from "../../assets/video/home-who-we-are/video.mp4";
import shape_wave from "../../assets/images/home/who-we-are/wave.svg";
import img_hand from "../../assets/images/home/we-promise/slides-obj.svg";
import shape_hand_outline from "../../assets/images/home/we-promise/shape_hand.svg";
import shape_hand from "../../assets/images/home/shape_hand_3d.png";
import banner_about_one from "../../assets/images/home/banner_about_one.png";
import banner_about_two from "../../assets/images/home/banner_about_two.png";
import postcard_img_01 from "../../assets/images/home/home-news/blog-card-img-01.png";
import postcard_img_02 from "../../assets/images/home/home-news/blog-card-img-01.png";
import badge_certificate from "../../assets/images/badge_certificate.svg";
import bg_veg from "../../assets/images/bg_veg.jpg";
import bg_veg_webp from "../../assets/images/bg_veg.webp";
import healthImg from "../../assets/images/home/healthImg.png";
import thoughtsImg from "../../assets/images/home/thoughtsImg.png";

import story_banner from "../../assets/images/home/video-story/story_banner.jpg"

import icon_quote from "../../assets/images/icons/icon_quote.svg";
import icon_quote_outline from "../../assets/images/icons/icon_quote_outline.svg";
import leaf_mask from "../../assets/images/leaf_mask.svg";

import icon_leaf from "../../assets/images/home/icon_leaf.svg";
import icon_preservative from "../../assets/images/home/icon_preservative.svg";
import icon_recycle from "../../assets/images/home/icon_recycle.svg";

import shape_slider1 from "../../assets/images/home/we-promise/shape_slider1.svg";
import shape_slider2 from "../../assets/images/home/we-promise/shape_slider2.svg";
import shape_slider3 from "../../assets/images/home/we-promise/shape_slider3.svg";
import shape_slider4 from "../../assets/images/home/we-promise/shape_slider4.svg";

import shape_globe from "../../assets/images/home/shape_globe.svg";
import banner_one from "../../assets/images/home/banner/banner_one.jpg";
import banner_two from "../../assets/images/home/banner/banner_two.jpg";
import banner_three from "../../assets/images/home/banner/banner_three.jpg";
import banner_four from "../../assets/images/home/banner/banner_four.jpg";
import banner_five from "../../assets/images/home/banner/banner_five.png";

import banner_one_webp from "../../assets/images/home/banner/banner_one.webp";
import banner_two_webp from "../../assets/images/home/banner/banner_two.webp";
import banner_three_webp from "../../assets/images/home/banner/banner_three.webp";
import banner_four_webp from "../../assets/images/home/banner/banner_four.webp";
import banner_five_webp from "../../assets/images/home/banner/banner_five.webp";

import bannerIcon from "../../assets/images/home/banner/banner_icon.svg";

import slider_earth_main from "../../assets/images/slides/slider_earth_main.svg";
import slider_earth_cloud1 from "../../assets/images/slides/slider_earth_cloud1.svg";
import slider_earth_cloud2 from "../../assets/images/slides/slider_earth_cloud2.svg";
import slider_earth_sun from "../../assets/images/slides/slider_earth_sun.svg";

import slider_health_main from "../../assets/images/slides/slider_health_main.svg";
import slider_health_shape1 from "../../assets/images/slides/slider_health_shape1.svg";
import slider_health_shape2 from "../../assets/images/slides/slider_health_shape2.svg";
import slider_health_shape3 from "../../assets/images/slides/slider_health_shape3.svg";
import slider_health_shape4 from "../../assets/images/slides/slider_health_shape4.svg";
import slider_health_shape5 from "../../assets/images/slides/slider_health_shape5.svg";
import slider_health_shape6 from "../../assets/images/slides/slider_health_shape6.svg";

import slider_taste_main from "../../assets/images/slides/slider_taste_main.svg";
import slider_taste_shape1 from "../../assets/images/slides/slider_taste_shape1.svg";
import slider_taste_shape2 from "../../assets/images/slides/slider_taste_shape2.svg";
import slider_love_main from "../../assets/images/slides/slider_love_main.svg";
import slider_love_shape1 from "../../assets/images/slides/slider_love_shape1.svg";
import slider_love_shape2 from "../../assets/images/slides/slider_love_shape2.svg";
import slider_love_shape3 from "../../assets/images/slides/slider_love_shape3.svg";
import slider_love_shape4 from "../../assets/images/slides/slider_love_shape4.svg";
import slider_love_cloud from "../../assets/images/slides/shape_love_cloud.svg";

import banner_promise from "../../assets/images/promise/banner_promise.png";
import banner_promise_line from "../../assets/images/promise/promise_banner_line.svg";
import banner_promise_leaf from "../../assets/images/promise/promise_banner_leaf.png";
import banner_promise_beet from "../../assets/images/promise/promise_banner_beet.png";
import banner_promise_beans from "../../assets/images/promise/promise_banner_beans.png";
import banner_promise_potato from "../../assets/images/promise/promise_banner_potato.png";

import cover_better from "../../assets/images/promise/cover_better.png";
import cover_love from "../../assets/images/promise/cover_love.png";
import cover_taste from "../../assets/images/promise/cover_taste.png";
import cover_health from "../../assets/images/promise/cover_health.png";
import betterEarth from "../../assets/images/promise/betterEarth.png";
import goodHealth from "../../assets/images/promise/goodHealth.png";


import cover_thought_foodfor from "../../assets/images/foodfor/cover_thought_foodfor.png";
import cover_health_foodfor from "../../assets/images/foodfor/cover_health_foodfor.png";
import cover_taste_foodfor from "../../assets/images/foodfor/cover_taste_foodfor.png";
import cover_foodfor from "../../assets/images/foodfor/cover_foodfor.png";
// import foodForHealth from "../../assets/images/foodfor/foodForHealth.png";
import foodForHealth from "../../assets/images/foodfor/foodForHealth1.png";

import greanmeat_image_webp from "../../assets/images/who-we-are/about-green-meat/greanmeat_image.webp";
import greanmeat_image from "../../assets/images/who-we-are/about-green-meat/greanmeat_image.png";
import who_we_are_love_bg_webp from "../../assets/images/who-we-are/who-we-are-love/who_we_are_love_bg.webp";
import who_we_are_love_bg from "../../assets/images/who-we-are/who-we-are-love/who_we_are_love_bg.jpg";
import environmental_shape from "../../assets/images/who-we-are/environmental-impact/environmental_shape.svg";
import management_team_01 from "../../assets/images/who-we-are/management/img_01.png";
import management_team_01_webp from "../../assets/images/who-we-are/management/img_01.webp";
import management_team_02 from "../../assets/images/who-we-are/management/img_02.png";
import management_team_02_webp from "../../assets/images/who-we-are/management/img_02.webp";
// import theGreenMeat from "../../assets/images/who-we-are/about-green-meat/theGreenMeat.png";
import theGreenMeat from "../../assets/images/who-we-are/about-green-meat/theGreenMeat1.png";

import csirImg from "../../assets/images/who-we-are/about-green-meat/csir-cftri.png";
// import theGreenMeat_webp from "../../assets/images/who-we-are/about-green-meat/theGreenMeat.webp";
import theGreenMeat_webp from "../../assets/images/who-we-are/about-green-meat/theGreenMeat1.webp";
import whoWeAreImg from "../../assets/images/who-we-are/about-green-meat/whoweare.png";

import health_icon1 from "../../assets/images/health/health_icon1.svg";
import health_icon2 from "../../assets/images/health/health_icon2.svg";
import health_icon3 from "../../assets/images/health/health_icon3.svg";
import health_icon4 from "../../assets/images/health/health_icon4.svg";
import health_icon5 from "../../assets/images/health/health_icon5.svg";
import health_icon6 from "../../assets/images/health/health_icon6.svg";
import health_icon7 from "../../assets/images/health/health_icon7.svg";
import health_icon8 from "../../assets/images/health/health_icon8.svg";

import taste_icon1 from "../../assets/images/health/taste_icon1.svg";
import taste_icon2 from "../../assets/images/health/taste_icon2.svg";
import taste_icon3 from "../../assets/images/health/taste_icon3.svg";

import post_image_01 from "../../assets/images/spotlight/post_img_01.webp";
import post_image_02 from "../../assets/images/spotlight/post_img_02.webp";
import post_image_03 from "../../assets/images/spotlight/post_img_03.webp";
import post_image_04 from "../../assets/images/spotlight/post_img_04.webp";
import post_image_05 from "../../assets/images/spotlight/post_img_05.webp";
import post_image_06 from "../../assets/images/spotlight/post_img_06.webp";
import post_image_07 from "../../assets/images/spotlight/post_img_07.webp";
import post_image_08 from "../../assets/images/spotlight/post_img_08.webp";
import post_image_09 from "../../assets/images/spotlight/post_img_09.webp";
import post_image_10 from "../../assets/images/spotlight/post_img_10.webp";
import post_image_11 from "../../assets/images/spotlight/post_img_11.webp";

import loader from "../../assets/images/loader.gif";

import cardImage01 from "../../assets/images/popup/01.jpg";
import cardImage02 from "../../assets/images/popup/02.jpg";
import cardImage03 from "../../assets/images/popup/03-01.jpg";
import cardImage04 from "../../assets/images/popup/04.jpg";
import cardImage05 from "../../assets/images/popup/05.jpg";
import cardImage06 from "../../assets/images/popup/06.jpg";
import cardImage07 from "../../assets/images/popup/chance.svg";
import cardImage08 from "../../assets/images/popup/success.svg";
import cardImage09 from "../../assets/images/popup/07-01.jpg";
import cardImage10 from "../../assets/images/popup/08.jpg";
import cardImage11 from "../../assets/images/popup/09.jpg";
import cardImage12 from "../../assets/images/popup/10-01.jpg";
import cardImage13 from "../../assets/images/popup/11.jpg";
import cardImage14 from "../../assets/images/popup/12.jpg";
import cardImage15 from "../../assets/images/popup/13.jpg";
import cardImage16 from "../../assets/images/popup/14.jpg";
import cardImage17 from "../../assets/images/popup/15.jpg";
import cardImage18 from "../../assets/images/popup/16.jpg";
import cardImage19 from "../../assets/images/popup/17.jpg";
import cardImage20 from "../../assets/images/popup/18.jpg";



import facebookIcon from "../../assets/images/icons/facebook-icon.svg";
import twitterIcon from "../../assets/images/icons/twitter.svg";
import youtubeIcon from "../../assets/images/icons/youtube-icon.svg";
import instagramIcon from "../../assets/images/icons/instagram.svg";
import bookmark from "../../assets/images/icons/bookmark.svg";



import productImage from "../../assets/images/product.png";




import errorImage from "../../assets/images/404/404.png";


// import green_meat_story from "../../assets/video/green-meat-story.mp4";
import green_meat_story from "../../assets/video/green-meat-story2.mp4";

import founders_clip from "../../assets/video/founders-clip.mp4";


import thank_you_icon from "../../assets/images/icons/thankyou-icon.png";

import survay_skip_thank_you from "../../assets/images/survaySkip.svg";

const Assets = {
  logo,
  Blacklogo,
  shape_globe,
  contact_banner_burger,
  contact_banner_burger_webp,
  shape_leaf,
  shape_leaf_svg,
  logo_greeno,
  knowmore_video,
  shape_wave,
  img_hand,
  shape_hand,
  banner_about_one,
  banner_about_two,
  healthImg,
  thoughtsImg,
  postcard_img_01,
  postcard_img_02,
  badge_certificate,
  bg_veg,
  bg_veg_webp,
  icon_leaf,
  icon_preservative,
  icon_recycle,
  leaf1,
  leaf2,
  shape_hand_outline,

  shape_slider1,
  shape_slider2,
  shape_slider3,
  shape_slider4,
  bannerIcon,

  //slide - better earth
  slider_earth_main,
  slider_earth_cloud1,
  slider_earth_cloud2,
  slider_earth_sun,

  //slide - health

  slider_health_main,
  slider_health_shape1,
  slider_health_shape2,
  slider_health_shape3,
  slider_health_shape4,
  slider_health_shape5,
  slider_health_shape6,

  //slide -taste
  slider_taste_main,
  slider_taste_shape1,
  slider_taste_shape2,

  //slider - love
  slider_love_main,
  slider_love_shape1,
  slider_love_shape2,
  slider_love_shape3,
  slider_love_shape4,
  slider_love_cloud,
  icon_quote,
  icon_quote_outline,

  banner_promise,
  banner_promise_leaf,
  banner_promise_line,
  banner_promise_beet,
  banner_promise_beans,
  banner_promise_potato,

  leaf_mask,

  /* About */
  greanmeat_image_webp,
  greanmeat_image,
  theGreenMeat,
  csirImg,
  theGreenMeat_webp,
  whoWeAreImg,
  who_we_are_love_bg,
  who_we_are_love_bg_webp,
  environmental_shape,
  management_team_01,
  management_team_01_webp,
  management_team_02,
  management_team_02_webp,

  health_icon1,
  health_icon2,
  health_icon3,
  health_icon4,
  health_icon5,
  health_icon6,
  health_icon7,
  health_icon8,
  taste_icon1,
  taste_icon2,
  taste_icon3,

  post_image_01,
  post_image_02,
  post_image_03,
  post_image_04,
  post_image_05,
  post_image_06,
  post_image_07,
  post_image_08,
  post_image_09,
  post_image_10,
  post_image_11,
  cardImage01,
  cardImage02,
  cardImage03,
  cardImage04,
  cardImage05,
  cardImage06,
  loader,
  cover_better,
  betterEarth,
  goodHealth,
  cover_love,
  cover_taste,
  cover_health,

  cover_thought_foodfor,
  cover_health_foodfor,
  cover_taste_foodfor,
  foodForHealth,
  cover_foodfor,
  cardImage07,
  cardImage08,
  cardImage09,
  cardImage10,
  cardImage11,
  cardImage12,
  cardImage13,
  cardImage14,
  cardImage15,
  cardImage16,
  cardImage17,
  cardImage18,
  cardImage19,
  cardImage20,

  loader,

  banner_one,
  banner_two,
  banner_three,
  banner_four,
  banner_five,

  banner_one_webp,
  banner_two_webp,
  banner_three_webp,
  banner_four_webp,
  banner_five_webp,
  story_banner,



  facebookIcon,
  twitterIcon,
  youtubeIcon,
  instagramIcon,
  bookmark,


  productImage,


  errorImage,

  green_meat_story,

  founders_clip,

  thank_you_icon,

  survay_skip_thank_you
  
};
export default Assets;
